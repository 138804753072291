import FileSaver from 'file-saver';

export default {
    DownloadWithFileSaver(response)
    {
        FileSaver.saveAs(this.ResponseToBlob(response), response.filename);
        // Alternativ:
        //this.DownloadFile(response.data, response.filename);
    },

    ResponseToBlob(response)
    {
        var byteArray = this.ResponseToArray(response);
        return new Blob([byteArray], {type: 'application/pdf'});
    },
    ResponseToArray(response)
    {
        var byteCharacters = atob(response.data);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        return byteArray;
    },
    DownloadFile(response, filename) 
    {
        var binaryString = window.atob(response);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++)
        {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        var newBlob = new Blob([bytes], {type: 'application/pdf'});
        
        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement('a');
        link.href = data;
        link.download = filename;
        link.click();
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
        }, 100);
    }
}